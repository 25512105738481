export const LiveStream = () => {
  return (
    <div id='live' className='team2'>
      <a id="LiveStream" className="anchor"></a>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title text-center'>
          <h2>Live Stream</h2>
        </div>
        <div className='col-md-8 col-md-offset-2 section-title text-center'>
          <p>Stream begins July 23, 7pm PDT </p>
          <video controls style={{width: "100%"}}>
            <source src="https://webm.stringsandclari.net/stream.webm"
                type="video/webm"/>
            <source src="http://hls-dash.stringsandclari.net/hls/feed.m3u8"
                type="application/vnd.apple.mpegurl"/>
            <source src="http://hls-dash.stringsandclari.net/dash/feed.mpd"
                type="application/dash+xml"/>
          </video>
        </div>
      </div>
    </div>
  )
}
