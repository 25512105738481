export const Bio = ({headshot, name, bio}) => {
  return (
    <div className='row composer'>
      <div className='col-sm-4'>
        <img className='img-responsive' src={headshot} alt=""></img>
      </div>
      <div className='col-sm-8'>
        <h3>{name}</h3>
        <p>{bio}</p>
      </div>
    </div>
  )
}
