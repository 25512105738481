import { Bio } from "../bio";

export const Musicians = (props) => {
  return (
    <div id='team' className='team2'>
      <a id="Musicians" className="anchor"></a>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title text-center'>
          <h2>About the Musicians</h2>
        </div>
	  <Bio name="Stephen Provine, violin" headshot="img/stephen-provine-headshot.jpg" bio="Stephen Provine is the concertmaster of Harmonia Orchestra & Chorus and a former concertmaster of the Cascade Symphony Orchestra. He has a degree in Computer Science from Harvard University, where he was concertmaster of the Bach Society Orchestra and studied chamber music with Robert Levin. Stephen grew up in the UK, studying with Richard Deakin, Lesley Hatfield and Harry Cawood. He has been a software engineer in the Developer Division at Microsoft for 22 years, where he currently works on engineering systems with a focus on supply chain security. Stephen lives in Bellevue with his wife, two daughters and Bella, the cat." />

	  <Bio name="Emily Acri, violin" headshot="img/emily-acri-headshot.jpg" bio="Violinist Dr. Emily Acri was born and raised in Winnetka, IL. Her collegiate studies spanned from 2011-2020 at Indiana University (BM ‘15), University of Michigan (MM ‘17) and University of Washington (DMA ‘20). After earning her doctorate, she worked for AmeriCorps as an ArtistYear Fellow (Colorado). There, she worked as music educator at Crystal River Elementary School and Aspen Music Festival and School’s Beginning Strings program. She was concertmaster of the High Country Sinfonia and co-founded the Sopris Quartet. Currently, Emily is based in Seattle, working as a violin and viola teacher and freelance performer. In addition to her private teaching, she coaches violinists in the Seattle Youth Symphony Orchestra. She performs with ensembles including Puget Sound Strings, Symphony Tacoma, Federal Way Symphony, Sound Ensemble (Pacific Northwest), Juliani Ensemble (Chicago, IL) and MahlerFest (Boulder, CO). Emily attributes her musical inspiration to her late Grandfather, Robert Acri, a legendary Chicago pianist." />

	  <Bio name="Elliot Isaacson, viola" headshot="img/ellioi-headshot.jpeg" bio="Elliot received a Bachelors of Music from the Juilliard School and a Masters of Music from the Cleveland Institute of Music. He studied with Karen Dreyfus, Heidi Castleman, Misha Amory, Stanley Konopka, and Melia Watras. He is currently a Senior Software Development Engineer at Amazon Prime Video Search." />

            <Bio name="Chris Young, cello" headshot="img/chris-young-headshot.jpg" bio="Cellist Chris Young grew up in Idaho Falls, Idaho, where he began studying cello in the year 2000 after hearing a classmate perform at school. He graduated high school from Interlochen Arts Academy in Michigan, then went on to earn music degrees from the University of Miami and the University of Michigan. He also studied in the doctoral music program at the University of Washington. Currently, Chris works as a freelance cellist throughout the Puget Sound region.  He serves as Assistant Principal Cello in Symphony Tacoma, and teaches many private students in Seattle and Bellevue. His previous teachers include Richard Aaron, Crispin Campbell, Ross Harbaugh, Janet McIntosh, Sæunn Thorsteinsdóttir, and Robert Tueller. Chris plays a cello made by Christopher Dungey that is generously loaned to him by the Wong/Engel Cello Library." />

	<Bio name="Hsing-Hui Hsu, clarinet" headshot="img/hhh-headshot.jpeg" bio="Hsing-Hui Hsu received her Bachelors of Music in clarinet performance from Rice University, where she also served as Music Director of the Rice Light Opera Society. While working as a software engineer at Amazon, she helped start the Amazon Symphony Orchestra and served as Music Director for four years. During the day, Hsing-Hui still works as a software engineer with a focus on infrastructure and build systems, and by night she plays with the Puget Sound Symphony Orchestra and Seattle Philharmonic and will be joining the Yakima Symphony this fall. Her principal teachers include Sidney Forrest, David Peck, Michael Webster, Thomas LeGrand, and Laurie de Luca. Hsing-Hui lives in Seattle where she also enjoys baking, playing piano, and going on hikes with her dog, Archer."/>

	  <Bio name="William C. White, composer" headshot="img/will-white-headshot.jpeg" bio="William C. White is a composer, conductor, teacher, writer, and performer based in Seattle, WA. Equally known for his original music as for his bold interpretations, Mr. White is an innovative programmer and conscientious leader in the musical community.  Mr. White currently serves as music director of Harmonia, a unique performing ensemble comprised of a chorus and orchestra that concertize as one. He maintains a significant career as a composer of music for the concert stage, theater, cinema, church, radio, and film. His music has been performed throughout North America as well as in Asia and Europe and has been recorded on the MSR Classics, Navona, and Cedille Record labels.  Mr. White earned a BA in Music from the University of Chicago and a masters degree in Conducting from Indiana University's Jacobs School of Music. He originally hails from Bethesda, MD, where he began his musical training as a violist.  Recordings of his works can be heard at his website, www.willcwhite.com, where he also maintains a blog and publishing business."/>

      </div>
    </div>
  )
}
