export const Program = (props) => {
  return (
    <div id='features'>
      <a id='Program' className='anchor'></a>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title text-center'>
          <h2>Program</h2>
        </div>
        <div className='container'>
          <div className='row piece'>
            <div className='col-md-5 col-md-push-7'>
               Clarinet Quintet, Op. 55, mvt. 1<br/>
	       <i>(World Premiere)</i>
            </div>
            <div className='col-md-4 col-md-pull-2 composer'>
               William C. White
            </div>
          </div>
          <div className='row piece'>
            <div className='col-md-5 col-md-push-7'>
               Quintet for Clarinet and String Quartet, Op. 30
            </div>
            <div className='col-md-4 col-md-pull-2 composer'>
	       Paul Hindemith
            </div>
          </div>
          <div className='row piece'>
            <div className='col-md-5 col-md-push-7'>
               Clarinet Quintet in B-minor, Op. 115
            </div>
            <div className='col-md-4 col-md-pull-2 composer'>
	      Johannes Brahms
            </div>
          </div>
          <div className='row performer'>
            <div className='col-md-7 col-md-offset-5'>
              Stephen Provine, violin<br/>
              Emily Acri, violin<br/>
              Elliot Isaacson, viola<br/>
              Chris Young, cello<br/>
              Hsing-Hui Hsu, clarinet<br/>
            </div>
          </div>
          </div>
        </div>
      </div>
  )
}
