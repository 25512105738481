export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-xs-12 intro-text'>
                <h1>
                <br/><span>Music for Clarinet<br/>and Strings</span></h1>
                <ul>
                  <li>July 23, 2023 @ 7pm</li>
                  <li>Seattle, WA</li>
                </ul>
                <a
                  href='#Program'
                  className='btn btn-custom btn-lg page-scroll'
                >
                  Learn More
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
