import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Program } from "./components/quintet_2023/program";
import { About } from "./components/quintet_2023/about";
import { Musicians } from "./components/quintet_2023/musicians";
import { LiveStream } from "./components/LiveStream";
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  return (
    <div>
      <Navigation />
      <Header />
      <Program/>
      <About />
      <Musicians/>
      <LiveStream/>
    </div>
  );
};

export default App;
