export const About = (props) => {
  return (
    <div id="about">
      <a id="JoinUs" className="anchor"></a>
      <div className="container">
        <div style={{marginBottom: 25}} className='col-md-10 col-md-offset-1 section-title text-center'>
          <h2>Join Us</h2>
          <p style={{marginTop: 0}}>These performances are presented free of charge.</p>
          <p style={{marginTop: 0}}>Consider a donation to <a href="https://www.emeraldcitymusic.org/donate">Emerald City Music</a> to
          support other innovative concerts.</p>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/st-clements.png" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
	    <div className="about-text">
	      <h3>Seattle</h3><h4 style={{fontSize: 24}}>Sunday, July 23, 2023, 7pm</h4>
	        <h4>
	          St. Clement’s Episcopal Church
	        </h4>
	        <h5>
	          1501 32nd Ave S, Seattle, WA 98144
	        </h5>
	  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2690.899943839141!2d-122.29457472327852!3d47.589188189179225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906af3e4259529%3A0x6d5d3c8869986159!2sSt.%20Clement&#39;s%20Episcopal%20Church!5e0!3m2!1sen!2sus!4v1688779672736!5m2!1sen!2sus" width="400" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <br/>
                <div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

